body, html
  //cursor: none
  font-family: 'Inconsolata', monospace
  background-color: #111
  color: #fafafa
  text-align: center
  height: 100%
  overflow: hidden

#app
  height: 100%
  display: flex
  align-items: center
  justify-content: center

#log
  display: none

#term
  font-size: 1.6vmin 
  font-family: 'Inconsolata', monospace
  margin: auto
